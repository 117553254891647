import React from 'react';
import LandingPageTemplate from '../../components/LandingPageTemplate';

import mainImage from '../../images/landingpage_risiko_1600_900_v1.webp';
import {Link} from 'gatsby';
import RiskAnalysisForm from '../../components/RiskAnalysisForm';
import fotoanleitung from '../../images/risikoanalyse_fotoanleitung.jpg';


const context = {
  title: 'Wie sicher ist meine Automatikschiebetür?', // Große Haupt-Überschrift
  subTitle: 'Automatiktür24 - aktuelle Themen', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Bewertung Ihrer Automatikschiebetür für mehr Sicherheit.',
  image: mainImage, //Haupt-Bild der Landingpage
  imageAlt: "", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Kontaktformular",
  suggestedProducts: [
    '/produkte/automatische-schiebetuer/',
    '/produkte/automatische-teleskopschiebetuer/',
    '/produkte/break-out-automatikschiebetuer/'
  ],
  text:
    <div>
      <p className="pb-5">Jeder von uns nutzt täglich in der Apotheke, im Supermarkt oder bei der
        Arbeit <Link to="/produkte/automatische-schiebetuer/">automatische Schiebetüren</Link> ohne
        sich Gedanken über deren Funktion und Zustand zu machen.</p>

      <p className="pb-5">Bei der Öffnungs- und Schließbewegung einer automatischen Tür lauern Gefahren wie das Anstoßen,
        Quetschen oder Einziehen von Finger, Hand oder Körper.</p>

      <p className="pb-5">Um die Nutzer bestmöglich zu schützen gelten Normen und Richtlinien
        (<b>DIN 18650, DIN EN 165005, AutSchR, ASR 1.7</b>)
        die Sicherheitsabstände definieren, welche Betriebskräfte einzuhalten sind
        und wo Sensorik eine Berührung verhindern muss.
        Hier gilt das Motto – Sicherheit um jeden Preis und nicht auf Kosten anderer!</p>

      <p className="pb-5">Die Risikobewertung zeigt somit die Gefahren auf, die sich bei der Nutzung der automatischen Türen ergeben.
        Zur Absicherung bieten wir Ihnen geeignete Sicherheitseinrichtungen an.
        Diese müssen dann in der Risikobeurteilung dokumentiert werden.</p>

      <p className="pb-5">Regelmäßige Wartungen, UVV und Funktionsprüfungen sowie eine Risikobeurteilung
        stellen die einwandfreie Betriebssicherheit fest.
        Durch nachträgliche Modernisierung oder Umbauten und Anbauten im Bereich der Automatikschiebetüren
        in Ihren Räumlichkeiten verändern sich die Anforderungen, die Risikoanalyse muss dann aktualisiert werden.</p>

      <p className="pb-5">„Die Norm DIN 18650 Teil 1 + 2 behandeln automatische Türsysteme,
        die in Rettungswegen eingebaut, sowie als Feuer- oder Rauchschutztüren eingesetzt werden,
        wie automatische Türsysteme für andere Anwendungen. Dazu gehören automatische Schiebe-,
        Drehflügel- und Karusselltüren, einschließlich Drehschiebetüren, sowie Faltflügeltüren mit
        horizontal bewegten Flügeln. Diese Art von Türen können elektromechanisch,
        elektrohydraulisch oder pneumatisch betrieben werden.“</p>

      <p className="pb-5"><i>#gegenQuetschen</i> <i>#gegenAnstoßen</i> <i>#gegenScheren</i> <i>#gegenEinziehen</i> - gemeinsam sicher.</p>

      <div className="container pt-8 xl:flex">

        <div className="xl:w-1/2 pr-5">
          <h2 className="pb-3 lg:text-xl font-semibold">Jetzt online Risikoanalyse erstellen</h2>
          Bitte fotografieren Sie für die Risikoanalyse Ihre automatische Schiebetür von der Innenseite aus drei bis vier Metern Entfernung.
          Bei Bedarf können Sie auch mehrere Fotos hochladen.
          <div className="w-full sm:w-2/3 md:w-full lg:w-2/3 xl:w-full flex justify-center items-center py-5">
            <img src={fotoanleitung} className="rounded-lg shadow-md" alt="Anleitung zum Foto für Risikoanlyse" />
          </div>
        </div>

        <div className="xl:w-1/2">
          <RiskAnalysisForm />
        </div>

      </div>




    </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
